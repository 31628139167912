import { gql } from "@apollo/client";
import type {
  CellId,
  HexVersionId,
  MagicCompletionEventId,
  ScopeItemType,
} from "@hex/common";
import {
  AppSessionId,
  CellType,
  HEX_PRIVATE_PREFIX,
  asciiCompare,
  notEmpty,
  sleep,
  uuid,
} from "@hex/common";
import {
  CancellationToken,
  editor as Editor,
  Position,
  Range,
  languages,
} from "monaco-editor";

import { client } from "../../../client";
import { RootStore } from "../../../redux/hooks.js";
import { appSessionMPSelectors } from "../../../redux/slices/appSessionMPSlice.js";
import { hexVersionMPSelectors } from "../../../redux/slices/hexVersionMPSlice.js";
import { getCellIdForModel } from "../../../state/models/useModel.js";

import { BroadcastCompletionProvider } from "./BroadcastCompletionProvider";
import {
  GetCompletionsDocument,
  GetCompletionsQuery,
  GetCompletionsQueryVariables,
  GetInlineCompletionsDocument,
  GetInlineCompletionsQuery,
  GetInlineCompletionsQueryVariables,
  TrackInlineCompletionEventCreatedDocument,
  TrackInlineCompletionEventCreatedMutation,
  TrackInlineCompletionEventCreatedMutationVariables,
  TrackInlineCompletionEventReviewedDocument,
  TrackInlineCompletionEventReviewedMutation,
  TrackInlineCompletionEventReviewedMutationVariables,
} from "./KernelCompletionProvider.generated";
import { TRACK_MAGIC_INLINE_ACCEPTED_CMD } from "./useMonacoCompletion.js";

const INLINE_COMPLETION_DEBOUNCE = 250;
const INLINE_COMPLETION_MAX_UPSTREAM_CHAR = 10_000;
const INLINE_COMPLETION_MAX_DOWNSTREAM_CHAR = 10_000;
const DF_PREAMBLE = `~~~Available dataframes~~~`;

gql`
  query GetCompletions(
    $appSessionId: AppSessionId!
    $source: String!
    $position: Int!
  ) {
    getCompletions(
      appSessionId: $appSessionId
      source: $source
      position: $position
    ) {
      matches {
        name
        type
      }
      cursorStart
      cursorEnd
    }
  }
`;

const getKind = (kind: string): languages.CompletionItemKind => {
  switch (kind) {
    case "function":
      return languages.CompletionItemKind.Function;
    case "keyword":
      return languages.CompletionItemKind.Keyword;
    case "class":
      return languages.CompletionItemKind.Class;
    case "module":
      return languages.CompletionItemKind.Module;
    case "statement":
    case "instance":
    default:
      return languages.CompletionItemKind.Variable;
  }
};

export interface KernelCompletion {
  name: string;
  type: ScopeItemType;
}

gql`
  query GetInlineCompletions(
    $cellId: CellId!
    $context: String!
    $prefix: String!
    $suffix: String!
    $completionId: MagicCompletionEventId!
  ) {
    getInlineCompletions(
      cellId: $cellId
      context: $context
      prefix: $prefix
      suffix: $suffix
      completionId: $completionId
    ) {
      result
      duration
      startOffset
      endOffset
      providerCompletionId
    }
  }
`;

gql`
  mutation TrackInlineCompletionEventCreated(
    $cellId: CellId!
    $context: String!
    $prefix: String!
    $suffix: String!
    $completion: String!
    $completionId: MagicCompletionEventId!
    $duration: Int!
    $startOffset: Int
    $endOffset: Int
    $providerCompletionId: String
  ) {
    trackInlineCompletionEventCreated(
      cellId: $cellId
      context: $context
      prefix: $prefix
      suffix: $suffix
      completion: $completion
      completionId: $completionId
      duration: $duration
      startOffset: $startOffset
      endOffset: $endOffset
      providerCompletionId: $providerCompletionId
    )
  }
`;

gql`
  mutation trackInlineCompletionEventReviewed(
    $completionId: MagicCompletionEventId!
    $accepted: Boolean!
  ) {
    trackInlineCompletionEventReviewed(
      completionId: $completionId
      accepted: $accepted
    )
  }
`;

export interface MagicCompletionData {
  context: string;
  prefix: string;
  suffix: string;
  cellId: CellId;
  completion: string;
  completionId: MagicCompletionEventId;
  duration: number;
  startOffset: number | null;
  endOffset: number | null;
  providerCompletionId: string | null;
  accepted: boolean;
  displayed: boolean;
}

export class InlineCodeCompletionProvider
  implements languages.InlineCompletionsProvider
{
  private hexVersionId?: HexVersionId;
  private appSessionId?: AppSessionId;
  private store?: RootStore;
  private enabledForCode = false;
  private enabledForSql = false;
  private enabledForMarkdown = false;
  public lastCompletion?: MagicCompletionData;
  private userSettingEnabled = false;
  public update({
    appSessionId,
    enabledForCode,
    enabledForMarkdown,
    enabledForSql,
    hexVersionId,
    store,
  }: {
    hexVersionId: HexVersionId;
    appSessionId: AppSessionId;
    store: RootStore;
    enabledForSql: boolean;
    enabledForCode: boolean;
    enabledForMarkdown: boolean;
  }): void {
    this.hexVersionId = hexVersionId;
    this.appSessionId = appSessionId;
    this.store = store;
    this.enabledForCode = enabledForCode;
    this.enabledForSql = enabledForSql;
    this.enabledForMarkdown = enabledForMarkdown;
  }

  public enableOrDisable(enabled: boolean): void {
    this.userSettingEnabled = enabled;
  }

  async provideInlineCompletions(
    model: Editor.ITextModel,
    position: Position,
    _context: languages.InlineCompletionContext,
    token: CancellationToken,
  ): Promise<languages.InlineCompletions | undefined> {
    await sleep(INLINE_COMPLETION_DEBOUNCE);
    if (token.isCancellationRequested || !this.userSettingEnabled) {
      return undefined;
    }
    // Whenever we generate a new completion, and the last one was NOT accepted, (but
    // WAS displayed), we need to track that it was rejected
    if (
      this.lastCompletion != null &&
      this.lastCompletion.displayed &&
      !this.lastCompletion.accepted
    ) {
      void client.mutate<
        TrackInlineCompletionEventReviewedMutation,
        TrackInlineCompletionEventReviewedMutationVariables
      >({
        mutation: TrackInlineCompletionEventReviewedDocument,
        variables: {
          completionId: this.lastCompletion.completionId,
          accepted: false,
        },
      });
      this.lastCompletion = undefined;
    }

    if (
      this.hexVersionId == null ||
      this.store == null ||
      this.appSessionId == null
    ) {
      return undefined;
    }
    const cellId = getCellIdForModel(model);
    if (cellId == null) {
      console.error("No cell matching model");
      return undefined;
    }
    const cells = hexVersionMPSelectors
      .getCellSelectors(this.hexVersionId)
      .selectAll(this.store.getState());

    const targetCell = cells?.find((c) => c.id === cellId);
    if (cells == null || !targetCell) {
      console.error("No cells found!");
      return undefined;
    }
    if (
      (targetCell.cellType === CellType.SQL && !this.enabledForSql) ||
      (targetCell.cellType === CellType.CODE && !this.enabledForCode) ||
      (targetCell.cellType === CellType.MARKDOWN && !this.enabledForMarkdown)
    ) {
      return undefined;
    }

    // Below we generate a prompt that combines upstream cell sources, dataframe descriptions,
    // and pre-cursor cell content, e.g.:
    // ```
    // <cell 1 source>
    //
    //
    // <cell 2 source>
    //
    //
    // ...<other cell sources>...
    //
    //
    // """
    // Available dataframes:
    // df_name: {
    //     "column_1": <coltype>
    //     ...
    // }
    // ...<other dataframes>...
    // """
    //
    // ...<pre-cursor cell content>...
    // ```
    // The suffix is just the contents of the target cell after the cursor

    const upstreamCellsOfSameType = cells.filter(
      (c) =>
        asciiCompare(targetCell.order, c.order) === 1 &&
        c.cellType === targetCell.cellType,
    );
    const upstreamCellContents = hexVersionMPSelectors
      .getCellContentSelectors(this.hexVersionId)
      .selectByCellIds(
        this.store.getState(),
        upstreamCellsOfSameType.map((c) => c.id),
      );

    const preCursorCellContent = model.getValueInRange({
      startLineNumber: 1,
      startColumn: 1,
      endLineNumber: position.lineNumber,
      endColumn: position.column,
    });
    if (preCursorCellContent.trim().length === 0) {
      return undefined;
    }

    const upstreamCellSources = upstreamCellContents
      ? upstreamCellsOfSameType.map((c) => {
          const contents = upstreamCellContents[c.id];
          if (
            contents.__typename === "CodeCell" ||
            contents.__typename === "SqlCell" ||
            contents.__typename === "MarkdownCell"
          ) {
            const source = contents.source.trim();
            if (source.length > 0) {
              return source;
            }
          }
          return null;
        })
      : [];
    let dfPromptInfo: string[] = [];
    if (targetCell.cellType === CellType.CODE) {
      const scopeInfo = appSessionMPSelectors
        .getScopeSelectors(this.appSessionId)
        .selectAll(this.store.getState());
      const dfs = (scopeInfo ?? [])
        .filter((item) => item.dataFrameSchema != null)
        .map(
          (item) =>
            `${item.name}: {\n${Object.entries(
              item.dataFrameSchema?.columns ?? {},
            )
              .map(([k, v]) => `    "${k}": "${v}"`)
              .join("\n")}\n}`,
        );
      if (dfs.length > 0) {
        dfPromptInfo = ['"""', DF_PREAMBLE, ...dfs, '"""'];
      }
    }
    let context = upstreamCellSources
      .filter(notEmpty)
      .filter((elem) => elem.length > 0)
      .join("\n\n\n");
    if (dfPromptInfo.length > 0) {
      context += "\n\n\n" + dfPromptInfo.join("\n") + "\n\n";
    } else {
      context = context + "\n\n\n";
    }
    context = context.slice(-INLINE_COMPLETION_MAX_UPSTREAM_CHAR);

    if (preCursorCellContent.trim() === "") {
      return undefined;
    }
    const fullRange = model.getFullModelRange();
    const suffix = model
      .getValueInRange({
        startLineNumber: position.lineNumber,
        startColumn: position.column,
        endLineNumber: fullRange.endLineNumber,
        endColumn: fullRange.endColumn,
      })
      .slice(0, INLINE_COMPLETION_MAX_DOWNSTREAM_CHAR);
    let result: string;
    let duration: number;
    let startOffset: number | null;
    let endOffset: number | null;
    let providerCompletionId: string | null;
    // note we use this as the request ID for augment.
    const completionId = uuid() as MagicCompletionEventId;
    try {
      const { data } = await client.query<
        GetInlineCompletionsQuery,
        GetInlineCompletionsQueryVariables
      >({
        query: GetInlineCompletionsDocument,
        variables: {
          cellId,
          completionId,
          context: context.trim().length > 0 ? context : "",
          prefix: preCursorCellContent,
          suffix,
        },
      });
      ({ duration, endOffset, providerCompletionId, result, startOffset } =
        data.getInlineCompletions);
    } catch (_e) {
      return undefined;
    }

    // Prevent possible prompt leak by filtering out any completions that contain preamble text
    if (
      result.trim().length === 0 ||
      startOffset == null ||
      endOffset == null ||
      result.toLowerCase().includes(DF_PREAMBLE.toLowerCase())
    ) {
      return undefined;
    }
    this.lastCompletion = {
      context,
      prefix: preCursorCellContent,
      suffix,
      cellId,
      completion: result,
      completionId,
      duration,
      startOffset,
      endOffset,
      providerCompletionId,
      accepted: false,
      displayed: false,
    };
    return {
      items: [
        {
          insertText: result,
          range: Range.fromPositions(
            model.getPositionAt(startOffset),
            model.getPositionAt(endOffset),
          ),
          // NB: There are two possible ways for a user to accept an inline completion:
          // 1. If they accept a full completion, we need to use a registered editor command
          //    that is automatically invoked when the user tab-completes the suggestion.
          // 2. If they accept a partial completion (i.e. by typing some of the characters in the
          //    the completion, then accepting, we need to rely on the `handlePartialAccept` method
          //    defined below.
          // These aren't meaningfully different for our purposes, so we hit the same endpoint in both
          // cases.
          command: {
            id: TRACK_MAGIC_INLINE_ACCEPTED_CMD,
            title: TRACK_MAGIC_INLINE_ACCEPTED_CMD,
            arguments: [this.lastCompletion],
          },
        },
      ],
      enableForwardStability: true,
    };
  }
  freeInlineCompletions(
    _completions: languages.InlineCompletions<languages.InlineCompletion>,
  ): void {
    return;
  }

  handlePartialAccept(): void {
    if (this.lastCompletion == null) {
      return;
    }
    this.lastCompletion.accepted = true;
    void client.mutate<
      TrackInlineCompletionEventReviewedMutation,
      TrackInlineCompletionEventReviewedMutationVariables
    >({
      mutation: TrackInlineCompletionEventReviewedDocument,
      variables: {
        completionId: this.lastCompletion.completionId,
        accepted: true,
      },
    });
  }
  handleItemDidShow(): void {
    if (this.lastCompletion == null) {
      return;
    }
    this.lastCompletion.displayed = true;
    void client.mutate<
      TrackInlineCompletionEventCreatedMutation,
      TrackInlineCompletionEventCreatedMutationVariables
    >({
      mutation: TrackInlineCompletionEventCreatedDocument,
      variables: this.lastCompletion,
    });
  }
}

export class KernelCompletionProvider extends BroadcastCompletionProvider<KernelCompletion> {
  private appSessionId?: AppSessionId;

  public setAppSessionId(appSessionId: AppSessionId): void {
    this.appSessionId = appSessionId;
  }

  async provideCompletionItems(
    model: Editor.ITextModel,
    position: Position,
  ): Promise<languages.CompletionList> {
    if (!this.appSessionId || model.isDisposed()) {
      return { suggestions: [] };
    }
    const word = model.getWordAtPosition(position);
    const source = model.getValue();
    const offset = model.getOffsetAt(position);
    const { data } = await client.query<
      GetCompletionsQuery,
      GetCompletionsQueryVariables
    >({
      query: GetCompletionsDocument,
      variables: {
        appSessionId: this.appSessionId,
        source,
        position: offset,
      },
    });
    // model can be disposed while the above query is running
    if (model.isDisposed()) {
      return { suggestions: [] };
    }
    let startLineNumber = position.lineNumber;
    let endLineNumber = position.lineNumber;

    let startColumn = word ? word.startColumn : position.column;
    let endColumn = word ? word.endColumn : position.column;
    if (data.getCompletions.cursorStart) {
      const startPos = model.getPositionAt(data.getCompletions.cursorStart);
      startLineNumber = startPos.lineNumber;
      startColumn = startPos.column;
    }
    if (data.getCompletions.cursorEnd) {
      const endPos = model.getPositionAt(data.getCompletions.cursorEnd);
      endLineNumber = endPos.lineNumber;
      endColumn = endPos.column;
    }
    const existingCompletions = new Set<string>();
    return {
      suggestions: (data?.getCompletions.matches || [])
        // Filter completions already provided by parameter completion provider
        .filter((match) => !existingCompletions.has(match.name))
        // Filter the built in magics (most of them don't even work)
        .filter((match) => !match.name.startsWith("%"))
        // Filter out hex internal variables
        .filter((match) => !match.name.startsWith(HEX_PRIVATE_PREFIX))
        .map((match) => ({
          label: match.name,
          insertText: match.name,
          kind: getKind(match.type),
          range: {
            startLineNumber,
            endLineNumber,
            startColumn,
            endColumn,
          },
        })),
    };
  }
}

export const KERNEL_COMPLETION_PROVIDER = new KernelCompletionProvider();
export const INLINE_CODE_COMPLETION_PROVIDER =
  new InlineCodeCompletionProvider();
